import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import history from '../../../history'
import { trackInternalLinkClick, trackExternalLinkClick } from '../../../utilities/analyticsEventHelpers'
import {
  isExternalLink,
  extractRelativePath,
  isEmailAddress
} from '../../../utilities/linkHelpers'

const LinkAnchor = props => {
  const {
    children,
    className,
    href,
    linkProps,
    title,
    ariaLabel
  } = props

  return (
    <a
      className={className}
      href={href}
      title={title}
      {...linkProps}
      aria-label={ariaLabel}
    >
      {children}
    </a>
  )
}

LinkAnchor.propTypes = {
  /* see LinkHandler proper for real children validation */
  children: PropTypes.any,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  /* internal/external link handling props */
  linkProps: PropTypes.object,
  title: PropTypes.string
}

const LinkButton = props => {
  const {
    children,
    className,
    href,
    linkProps,
    title,
    ariaLabel,
    variant
  } = props

  return (
    <Button
      className={className}
      href={href}
      title={title}
      aria-label={ariaLabel}
      variant={variant}
      {...linkProps}
    >
      {children}
    </Button>
  )
}

LinkButton.propTypes = {
  /* see LinkHandler proper for real children validation */
  children: PropTypes.any,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  /* internal/external link handling props */
  linkProps: PropTypes.object,
  ariaLabel: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string
}

function LinkHandler (props) {
  const {
    children,
    href,
    label,
    noBs,
    routerData,
    event,
    analyticsOverride
  } = props

  const hrefIsExternal = isExternalLink(href)
  const hrefIsEmail = isEmailAddress(href)

  const handleInternalLinkClick = (e) => {
    const { location: { pathname: currentPathname } } = history

    e.preventDefault()

    const targetHref = e.currentTarget.getAttribute('href')
    const pathname = extractRelativePath(targetHref)
    /* may need feedback on alternative to pass in the case of innerHTML/icon */
    const targetElement = e.currentTarget.innerText !== '' ? e.currentTarget.innerText : e.currentTarget.innerHTML
    trackInternalLinkClick(event, pathname, targetElement, window.utag)
    // addresses situations where user clicks on link that is the same as the current location;
    // in these cases we do not want to continue to push duplicate entries onto the history stack;
    // this situation is most likely to happen in the global header menu or footer
    const historyMethod = pathname === currentPathname ? 'replace' : 'push'

    history[historyMethod]({
      pathname: pathname,
      ...routerData
    })
  }

  // link handling centers around internal/external prop sets (email links are treated as external links)
  const externalProps = {
    target: '_blank',
    ...(!hrefIsEmail ? { rel: 'noopener noreferrer' } : {}),
    onClick: (e) => analyticsOverride ? analyticsOverride(e) : trackExternalLinkClick(e, window.utag)
  }

  const internalProps = {
    onClick: analyticsOverride || handleInternalLinkClick
  }

  const linkProps = hrefIsExternal ? externalProps : internalProps

  const allProps = {
    ...props,
    linkProps
  }

  const LinkComponent = noBs ? LinkAnchor : LinkButton

  return (
    <LinkComponent {...allProps}>
      {label}
      {children}
    </LinkComponent>
  )
}

LinkHandler.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string]))
  ]),
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  label: PropTypes.string,
  /* opt out of any bootstrap styling for a plain <a> */
  noBs: PropTypes.bool,
  /* optional data to pass along to internal routes */
  routerData: PropTypes.object,
  /* native tooltip */
  title: PropTypes.string,
  /* bootstrap styling pass through */
  variant: PropTypes.string,
  /* optional analytics event data */
  event: PropTypes.object,
  /* Optional callback function to handle analytics outside of the standard internal/external tracking */
  analyticsOverride: PropTypes.func
}

LinkHandler.defaultProps = {
  noBs: false,
  routerData: {},
  title: '',
  variant: 'link'
}

export default LinkHandler
