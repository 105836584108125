import Analytics from './analyticsUtilities'
import ExternalLinkIcon from '../components/icons/ExternalLinkIcon'
import React from 'react'
import LinkLinkHandler from '../components/common/LinkHandler/LinkHandler'

export const shouldShowLink = (entry) => {
  return entry?.linkUrl || entry?.fieldRefWhatsnewInternalLink?.path?.alias || entry?.fieldWhatsnewExternalLink?.uri
}

export const renderLink = (entry, parentComponentType) => {
  const LinkLabelText = entry?.fieldRefWhatsnewLinkLabel?.name
  const isExternalLink = entry?.fieldWhatsnewUpdateOption === 'external_link'
  const internalLink = entry?.linkUrl ? entry?.linkUrl : entry?.fieldRefWhatsnewInternalLink?.path?.alias
  const externalLink = entry?.fieldWhatsnewExternalLink?.uri
  const linkUrl = isExternalLink ? externalLink : internalLink

  const trackClick = (e) => {
    e.stopPropagation()
    Analytics.trackEvent({
      event_name: isExternalLink ? 'external_link_click' : 'internal_link_click',
      link_url: e.currentTarget.getAttribute('href'),
      link_type: 'link_other',
      link_text: e.currentTarget.innerText,
      parent_component_type: parentComponentType
    }, window.utag)
  }

  return (
    <LinkLinkHandler noBs href={linkUrl} analyticsOverride={(e) => { trackClick(e) }}>
      {LinkLabelText}{isExternalLink ? <ExternalLinkIcon /> : ''}
    </LinkLinkHandler>)
}
